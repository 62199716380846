export const isFlightEnabled = (flightName: string) => {
	if (process.client) {
		const Statsig = window.$statsig;
		if (Statsig) {
			return Statsig.checkGate(flightName);
		}
		throw new Error('Statsig not initialized');
	} else {
		return;
	}
};

export const isValhallaReverseGateEnabled = () =>
	isFlightEnabled('valhalla_reverse_gate');

export const isStripeGateEnabled = () => {
	return isFlightEnabled('stripe-card-payment');
};

export const isXpayGateEnabled = () => {
	return isFlightEnabled('xpay-card-payment');
};

export const isNewFiltersGateEnabled = () => {
	return isFlightEnabled('new-filters');
};

export const isIndependenceDayGateEnabled = () => {
	return isFlightEnabled('laam-independence-day');
};

export const isRewardsEnabled = () => {
	try {
		return isFlightEnabled('laam-rewards');
	} catch (error) {
		console.error(error);
		return false;
	}
};
